import React from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { BsCopy, BsSend } from "react-icons/bs";
import loaderGif from "./../assets/loader.gif";
import MobileNumberPopup from "../components/MobileNumberPopup";

const HomePage = () => {
  const location = useLocation();

  console.log("Data >>> ", location.state);

  const [formData, setFormData] = React.useState("");
  const [link, setLink] = React.useState("");
  const [frameUrl, setFrameUrl] = React.useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFormData(value);
  };

  const getiFrameUrl = () => {
    axios
      .get(
        `https://nodeapi.smartdietplanner.com/api/tmsdp/getFrameUrl/${location.state?.companyId}`
      )
      .then((res) => {
        console.log("Urll >> ", res.data);
        setFrameUrl(res.data.url);
      });
  };

  // Use useEffect to set up the interval
  React.useEffect(() => {
    if (location.state === null) {
      navigate("/");
      return;
    }
    // Call the API immediately when the component mounts
    if(frameUrl===""){
      getiFrameUrl();
    }

    // Set up the interval to call the API every 2 minutes (120000 milliseconds)
    // const intervalId = setInterval(() => {
    //   getiFrameUrl();
    // }, 120000);
    // Clean up the interval when the component unmounts
    // return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    setError("");

    if (loading) {
      return;
    }

    if (link !== "") {
      setFormData("");
      setLink("");
    } else {
      if (formData === "") {
        alert("Id required");
      } else {
        setLoading(true);

        const timer = setTimeout(() => {
          console.log("\n<<<---- Timmed out(1 minute) ---->> ");
          setLoading(false);
          setError("The request is taking too long, please try again later.");
        }, 60000);

        const body = {
          companyKey: location.state.companyKey,
          userId: formData,
          URLClientID: location.state.URLClientID,
        };

        try {
          const res = await axios.post(
            "https://nodeapi.smartdietplanner.com/api/tmsdp/genLink",
            body
          );
          console.log("API response >> ", res.data);
          clearTimeout(timer);
          setLink(res.data.url);
          setLoading(false);
        } catch (error) {
          console.error("Error: ", error);
          clearTimeout(timer);
          setError("An error occurred while fetching data.");
          setLoading(false);
        }
      }
    }
  };

  const handleLogout = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="w-full h-full py-5 px-4">
      <div className="flex justify-between">
        <img
          src="https://fitrofy.com/wp-content/uploads/2023/04/mainlogo.webp"
          alt="logo"
          className=""
        />

        <div className="flex items-center justify-between w-[15%]">
          <p className="font-semibold text-xl">v0.5</p>
          <button
            className="border-2 bg-blue-500 py-2 px-4 rounded-full my-auto font-bold text-white"
            onClick={handleLogout}
          >
            Log out
          </button>
        </div>
      </div>
      <h2 className="text-xl font-semibold mt-5">
        Welcome {location.state?.companyName}!
      </h2>

      <div className="p-10 pt-0 border-2 border-black mt-10 mx-10">
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <h2 className="text-xl font-semibold my-5 underline">
            Get Customer Link/Send Message
          </h2>
          <div className="flex gap-2 mb-5">
            <label htmlFor="userName" className="text-lg font-medium">
              Profile Id:
            </label>
            <input
              type="text"
              id="userName"
              name="userName"
              placeholder="Enter the Profile ID"
              value={formData}
              autoComplete="email"
              onChange={handleChange}
              className="border px-2"
            />
          </div>
          <button
            type="submit"
            className="mt-8 text-center w-[15rem] text-lg font-medium bg-blue-300 rounded-full py-2 mx-auto"
          >
            {loading ? (
              <img src={loaderGif} alt="loader" className="w-10 mx-auto" />
            ) : link ? (
              "Clear"
            ) : (
              "Generate Link"
            )}
          </button>
          {error && <p className="text-red-500">Error: {error}</p>}
        </form>
        {link && (
          <>
            <div className="flex mt-5 items-center gap-5">
              <span className="text-lg">Link generated::</span>

              <textarea
                name="link"
                id=""
                cols="110"
                rows="1"
                value={link}
                readOnly
                className="px-2"
              />
            </div>
            <div className="flex gap-10">
              <button
                className="border-2 border-blue-600 rounded-full py-2 px-8 flex items-center gap-2 mt-2"
                onClick={() => {
                  // Use the Clipboard API to copy text
                  navigator.clipboard
                    .writeText(link)
                    .then(() => {
                      alert("Text copied to clipboard!");
                    })
                    .catch((err) => {
                      console.error("Failed to copy: ", err);
                    });
                }}
              >
                Copy
                <BsCopy />
              </button>

              {location.state.companyKey === "BLUEBEIN2024" && "yes" && (
                <button
                  className="border-2 border-blue-600 rounded-full py-2 px-8 flex items-center gap-2 mt-2"
                  onClick={showPopup}
                >
                  Send Message
                  <BsSend />
                </button>
              )}

              {isPopupVisible && (
                <MobileNumberPopup
                  onClose={closePopup}
                  data={formData}
                  link={link}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="mt-10 border-2 h-[220rem] mx-10 mb-20 border-black">
        <h2 className="text-xl font-semibold my-5 underline text-center">
          Dashboard
        </h2>
        <iframe
          src={frameUrl}
          frameborder="0"
          title="Dashboard"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default HomePage;
