import axios from "axios";
import React, { useState } from "react";

const MobileNumberPopup = ({ onClose, data, link }) => {
  const [mobileNumber, setMobileNumber] = useState(data);
  const [error, setError] = useState("");

  // Function to validate mobile number
  const validateMobileNumber = () => {
    // Regex to check if the number is valid (10 digits)
    const mobileNumberPattern = /^[0-9]{10}$/;
    const validNumber = mobileNumberPattern.test(mobileNumber);
    if (validNumber) {
      setError("");
    } else {
      setError("Please enter a valid 10-digit mobile number.");
    }
    return validNumber;
  };

  const handleSend = () => {
    if (validateMobileNumber()) {
      let config = {
        method: "post",
        url: "https://nodeapi.smartdietplanner.com/api/wp/sendtestMsg",
        data: {
          tinyUrl: link,
          phone: mobileNumber,
          email: data
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          alert("Message sent");
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setError("Please enter a valid 10-digit mobile number.");
    }
  };

  const handleCancel = () => {
    setMobileNumber(""); // Reset input
    onClose(); // Close the popup
  };

  const handleOnChange = (e) => {
    const number = e.target.value;

    setMobileNumber(number);
    const mobileNumberPattern = /^[0-9]{10}$/;
    const validNumber = mobileNumberPattern.test(number);
    if (validNumber) {
      setError("");
    } else {
      setError("Please enter a valid 10-digit mobile number.");
    }
  };

  React.useEffect(() => {
    validateMobileNumber();
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative w-[30%]">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-black font-bold"
        >
          X
        </button>

        <h2 className="text-lg mb-4 mt-5 font-medium">Enter Mobile Number</h2>
        <input
          type="text"
          placeholder="Mobile Number"
          value={mobileNumber}
          onChange={handleOnChange}
          className="border p-2 mb-2 w-full"
        />
        {error && <p className="text-red-500 mb-2">{error}</p>}

        <div className="flex justify-between mt-5">
          <button
            onClick={handleCancel}
            className="bg-gray-500 rounded-full text-white px-4 py-1 hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            className="border-2 border-blue-600 rounded-full px-4 py-1 hover:bg-blue-600 hover:text-white"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileNumberPopup;
